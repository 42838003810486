(function () {
  'use strict';

  angular
    .module('neo.home.teams')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.teams', {
        url: '/teams',
        templateUrl: 'home/teams/teams.tpl.html',
        controller: 'TeamsCtrl',
        controllerAs: 'vm',
        params: {
          selectedEntity: undefined
        },
        resolve: {
          entities: function (Entitats) {
            return Entitats.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          genders: function(Genders){
            return Genders.query().$promise;
          },
          programmes: function(Programmes){
            return Programmes.query().$promise;
          }
        }
      });
  }
}());
